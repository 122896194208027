import $ from 'jquery';

/*
 * There are two "types" of authorization in this site.
 *
 * React's authorization (let's call this "React-Auth")
 * simply gives access to various protected resources, such as the homepage
 * or the user account page, but it does not give access to the DATA that those
 * pages need to actually do anything. This authorization drops off whenever
 * the page is reloaded and needs to be reassigned.
 *
 * The other authorization is granted by the server and stored within its
 * session. Let's call this "Server-Auth". Once authenticated, the session ID is
 * sent to the client and stored in a cookie, which is then sent to the server
 * in subsequent requests, and gives access to that user's actual data.
 *
 * If a user tries to go to a page protected by React-Auth, they can be redirected
 * to a login screen, but if they found a way around that, they still wouldn't
 * have the session granted by Server-Auth and so wouldn't have access to any
 * data. They'd just see an empty, useless HTML page. Once they log in,
 * React-Auth gives access to those protected pages, and those pages make
 * requests to the server for the appropriate data. Those requests include the
 * cookie with their session ID, which verifies that they have access to the
 * data. The data is sent, and displayed appropriately within the React page.
 */

class Auth {
  constructor() {
    this.authenticated = false;
  }

  login(user, pass_plaintext, successCallback, errorCallback) {
    const data = {
      username: user,
      password: pass_plaintext,
    };

    $.ajax({
      type: 'POST',
      // This URL generates the session and sends the cookie to the user
      url: '/api/public/login',
      data,
    })
      .then(response => {
        if (response.success) {
          this.authenticated = true; // React-Auth
          this.require_password_change = response.require_password_change;
          console.log('✅ Login Successful');
          successCallback();
        } else {
          console.log(`❌ ${response.error}`);
          errorCallback(response.error);
        }
      })
      .catch(response => {
        console.log('❌ ', response.responseJSON);
        errorCallback(response.responseJSON.error);
      });
  }

  async logout() {
    return new Promise((resolve, reject) => {
      $.get({
        url: '/api/public/logout',
        beforeSend: () => {
          console.log('Logging out...');
        },
        success: response => {
          this.authenticated = false;
          resolve(response.success);
        },
        error: response => {
          reject(response.responseJSON.error);
        },
      });
    });
  }

  async isAuthenticated() {
    // If the user isn't authenticated but we have a session token, check
    // whether the session is valid on the server. If it is, we're authenticated

    return $.get({url: '/api/public/login/verify'});
  }
}

export default new Auth();
