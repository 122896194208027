import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCircleNotch,
  faCircleExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import {prettyDate} from './elements.js';

// Component work around to support ESM modules https://github.com/Hacker0x01/react-datepicker/issues/3834#issuecomment-1404528448
const ReactDatePicker = DatePicker.default ?? DatePicker;

/**
 * Creates an input field with a label
 */
export const InputWithShiftingLabel = props => {
  /*
   * Setup
   */
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(props.defaultValue);
  const disabled = props.disabled || false;
  const focusClass = isFocused ? 'shifted' : '';

  /*
   * Functions
   */
  const handleChange = event => {
    setValue(event.target.value);
    props?.onChange(event.target.value);
  };
  const onFocus = event => {
    setIsFocused(true);
    event.target.select();
  };
  const onBlur = () => {
    if (!value) {
      setIsFocused(false);
    }
  };

  /*
   * Render
   */
  return (
    <label className={`inputDiv ${focusClass}`}>
      <span className="inputLabel">{props.label || ''}</span>
      <input
        value={value}
        onChange={handleChange}
        placeholder={props.placeholder}
        id={props.id}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        type={props.type}
      ></input>
    </label>
  );
};

/**
 * Creates an input field with a label
 */
export const Input = props => {
  /*
   * Setup
   */
  const [value, setValue] = useState(prettyDate(props.defaultValue) || '');
  const [dateIndicator, setDateIndicator] = useState('');
  const showDateIndicator = props.showDateIndicator;

  const type = props.type || 'text';
  const disabled = props.disabled || false;

  useEffect(() => {
    if (value !== prettyDate(props.defaultValue) && value !== '') {
      setDateIndicatorSubmitted();
      setValue(prettyDate(props.defaultValue));
    }
  }, [props.defaultValue]);

  if (props.shipDateChanged && dateIndicator === '' && showDateIndicator) {
    setDateIndicator(<FontAwesomeIcon icon={faCircleExclamation} />);
  }

  /*
   * Functions
   */

  /**
   * Set the date indicator error
   * @param title
   */
  const setDateIndicatorError = title => {
    if (showDateIndicator) {
      // Set Times symbol
      setDateIndicator(<FontAwesomeIcon icon={faXmark} title={title} />);

      // Remove the Times symbol after 8 seconds
      setTimeout(() => {
        setDateIndicator('');
      }, 6000);
    }
  };

  /**
   * Set the date indicator to submitted
   */
  const setDateIndicatorSubmitted = () => {
    if (showDateIndicator) {
      setDateIndicator(<FontAwesomeIcon icon={faCheck} />);

      // 5 seconds later, add a class the slowly hides the success checkbox.
      setTimeout(() => {
        setDateIndicator(
          <FontAwesomeIcon className={'dissolve'} icon={faCheck} />
        );
      }, 5000);

      // 8 seconds later, mark the line as pending approval
      setTimeout(() => {
        setDateIndicator(<FontAwesomeIcon icon={faCircleExclamation} />);
      }, 8000);
    }
  };

  /**
   * Set the date indicator to a loading circle
   */
  const setDateLoadingCircle = () => {
    if (showDateIndicator) {
      setDateIndicator(<FontAwesomeIcon icon={faCircleNotch} spin />);
    }
  };

  const handleChange = newDateValue => {
    //  Make sure date passed exists and is valid
    if (newDateValue && !isNaN(newDateValue)) {
      const newValue = prettyDate(newDateValue);

      if (newValue !== '' && newValue !== value) {
        // Check if parent has a dataValidation function
        if (props.dataValidation) {
          // Check if data valid
          if (!props.dataValidation(newDateValue)) {
            setDateIndicatorError('Invalid Date');
            return;
          }
        }

        // Check if parent has a handChange function
        if (props.handleChange) {
          setDateLoadingCircle();
          props.handleChange(newValue).then(result => {
            if (result.successes.length) {
              setDateIndicatorSubmitted();
            } else {
              setDateIndicatorError();
              return;
            }
          });
        }
        setValue(newValue);
      }
    } else if (newDateValue !== value) {
      setDateIndicatorError('Invalid Date');
    }
  };

  /*
   * Render
   */

  // Apply classes appropriately for days within a shipping window.
  const shipWindowRange = [
    {'highlightDate first': []},
    {'highlightDate middle': []},
    {'highlightDate last': []},
  ];
  if (props.shipWindow) {
    for (
      let d = new Date(props.shipWindow.start);
      d <= new Date(props.shipWindow.end);
      d.setDate(d.getDate() + 1)
    ) {
      const nextDay = new Date(d);
      nextDay.setDate(nextDay.getDate() + 1);

      // Check if the ship window is just one day
      if (props.shipWindow.start === props.shipWindow.end) {
        shipWindowRange[0]['highlightDate first'].push(new Date(d));
        shipWindowRange[2]['highlightDate last'].push(new Date(d));
        break;
      } else if (!shipWindowRange[0]['highlightDate first'].length) {
        shipWindowRange[0]['highlightDate first'].push(new Date(d));
      } else if (nextDay > new Date(props.shipWindow.end)) {
        shipWindowRange[2]['highlightDate last'].push(new Date(d));
      } else {
        shipWindowRange[1]['highlightDate middle'].push(new Date(d));
      }
    }
  }

  if (value === undefined || value === '') {
    setValue(null);
  }

  return (
    <>
      <span className={'updateStatus'}>{dateIndicator}</span>
      <ReactDatePicker
        selected={value ? new Date(value) : null}
        // disabledKeyboardNavigation
        highlightDates={
          props.shipWindow
            ? shipWindowRange
            : [
                {
                  start: 0,
                  end: undefined,
                },
              ]
        }
        // className={`${emptyFieldClass} ${props.className ? props.className : ''}`}
        type={type}
        onSelect={handleChange}
        placeholderText={props.placeholder ? props.placeholder : 'mm/dd/yyyy'}
        id={props.id}
        disabled={disabled ? disabled : ''}
        onBlur={({target: {value}}) => {
          handleChange(new Date(value));
        }} // We have to read the input target value of the element just for onBlur
        disabledKeyboardNavigation
        showMonthDropdown
      >
        <div className={'react-datepicker-child'}>
          {props.shipWindow ? (
            <p>
              Shipping Window
              <br />
              {`${props.shipWindow.start} to ${props.shipWindow.end}`}{' '}
            </p>
          ) : (
            ''
          )}{' '}
        </div>
      </ReactDatePicker>
    </>
  );
};
