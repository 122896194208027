import {
  countryAbbreviationToFullName,
  createBarcode,
  createQRCode,
  generateLineBreakText,
} from './utility.js';
import {degrees, rgb} from 'pdf-lib';
import {loadImage} from './loadImage.js';

const MAX_WIDTH = 72 * 3;
const MAX_HEIGHT = 72 * 2;

/**
 * Create a Pack or Master Pack label with QR code for the PO
 * @param {Object} label - The label object
 * @param {import('pdf-lib').PDFDocument} pdfDoc - The PDF document
 * @param {{
 *   Helvetica: import('pdf-lib').PDFFont,
 *   HelveticaBold: import('pdf-lib').PDFFont,
 * }} fonts - The fonts object
 * @returns {Promise<void>}
 */
export const generatePackLabelWithQRCode = async ({label, pdfDoc, fonts}) => {
  const page = pdfDoc.addPage();
  page.setSize(MAX_WIDTH, MAX_HEIGHT);

  // Load Tekton Logo label
  const sourcePngUrl = '/templates/TEKTON_Label_inner.png';
  const sourcePng = await loadImage(sourcePngUrl);
  const embedPng = await pdfDoc.embedPng(sourcePng);
  const embedPngDims = embedPng.scale((1 / 300) * 105);

  // If the vendor needs to show the QR as a PO, resize the Tekton logo and add a transaction QR code.

  const qrImage = await pdfDoc.embedPng(createQRCode(label.transId, 25));
  const dimQrImage = qrImage.scale(1.25);

  page.drawImage(qrImage, {
    x: 3,
    y: 110,
    width: dimQrImage.width,
    height: dimQrImage.height,
  });

  page.drawImage(embedPng, {
    x: 5,
    y: 5,
    height: embedPngDims.height,
    width: embedPngDims.width,
  });

  page.setFont(fonts.Helvetica);

  // Barcode
  let barcodeImage;
  if (!label.labelKey.includes('TOTE')) {
    barcodeImage = await pdfDoc.embedPng(
      createBarcode('ITF14', label.barcode, 35)
    );
  } else {
    barcodeImage = await pdfDoc.embedPng(
      createBarcode('CODE39', label.barcode, 35)
    );
  }
  const BARCODE_PADDING = 10;
  page.drawImage(barcodeImage, {
    x: 200,
    y: BARCODE_PADDING,
    width: MAX_HEIGHT - BARCODE_PADDING * 2,
    rotate: degrees(90),
  });

  page.drawText(label.barcode, {
    x: MAX_WIDTH - 5,
    y:
      fonts.Helvetica.widthOfTextAtSize(label.barcode, 11) / 2 -
      BARCODE_PADDING,
    size: 11,
    rotate: degrees(90),
  });

  const BLACK_BOX_CORNER_X = 37;

  // Black vertical line
  page.drawRectangle({
    x: BLACK_BOX_CORNER_X,
    y: 0,
    width: 1,
    height: MAX_HEIGHT,
    opacity: 1,
  });

  // Black horizontal lines
  page.drawRectangle({
    x: BLACK_BOX_CORNER_X,
    y: 75,
    width: 110,
    height: 1,
    opacity: 1,
  });
  page.drawRectangle({
    x: BLACK_BOX_CORNER_X,
    y: 38,
    width: 110,
    height: 1,
    opacity: 1,
  });
  // Black square
  page.drawRectangle({
    x: BLACK_BOX_CORNER_X + 83,
    y: 38,
    width: 38,
    height: 38,
    opacity: 1,
  });

  page.setFont(fonts.HelveticaBold);

  // Revision number
  page.drawText(String(label.revision), {
    x:
      BLACK_BOX_CORNER_X +
      102 -
      fonts.HelveticaBold.widthOfTextAtSize(label.revision.toString(), 20) / 2,
    y: 50,
    size: 20,
    color: rgb(1, 1, 1),
  });

  // Transaction ID (PO/WO)
  if (label.transId !== undefined) {
    page.drawText(label.transId, {
      x: 47,
      y: 59,
      size: 12,
      textAlign: 'center',
    });
  }

  // Draw quantity and adjust size and location if quantity is bigger than 1000.
  page.drawText(`Qty: ${label.quantity}`, {
    x: label.quantity < 1000 ? 46 : label.quantity < 10000 ? 44 : 43,
    y: 44,
    size: label.quantity < 1000 ? 12 : label.quantity < 10000 ? 11 : 10,
    color: rgb(0, 0, 0),
  });

  // Item Number
  const itemNumber = label.itemNumber;
  const salesChannel = itemNumber.slice(-2);
  if (salesChannel === '-D' || salesChannel === '-S') {
    // Draw sales channel box
    page.drawRectangle({
      x: 127,
      y: 114,
      width: 33,
      height: 26,
      borderWidth: 1,
      borderColor: rgb(0, 0, 0),
      opacity: 0,
      borderOpacity: 1,
    });

    // Display Sales Channel Letter
    page.drawText(salesChannel[1], {
      x: 135,
      y: 118,
      size: 25,
    });

    const fontSize = itemNumber.length < 10 ? 19 : 17;

    const slicedItemNumber = itemNumber.slice(0, -2);

    // Display Item Number
    page.drawText(slicedItemNumber, {
      x: 40,
      y: 120,
      size: fontSize,
    });
  } else {
    const fontSize = itemNumber.length < 10 ? 22 : 15;
    // Display Item Number
    page.drawText(itemNumber, {
      x: 100 - fonts.HelveticaBold.widthOfTextAtSize(itemNumber, fontSize) / 2,
      y: 118,
      size: fontSize,
    });
  }

  const DISPLAY_NAME_FONT_SIZE = 9;
  const DISPLAY_NAME_MAX_WIDTH = 120;

  // Item Display Name
  const displayName = generateLineBreakText(
    label.displayName,
    DISPLAY_NAME_MAX_WIDTH,
    4,
    fonts.HelveticaBold,
    DISPLAY_NAME_FONT_SIZE
  );

  page.drawText(displayName, {
    x: 40,
    y: 106,
    size: DISPLAY_NAME_FONT_SIZE,
    lineHeight: 9,
    maxWidth: DISPLAY_NAME_MAX_WIDTH,
  });

  // Master and Tote black boxes
  if (label.labelKey.includes('MASTER')) {
    // Create black backdrop around "Master" label
    page.drawRectangle({
      x: 42,
      y: 18,
      width: 56,
      height: 17,
      color: rgb(0, 0, 0),
    });

    const labelType = 'MASTER';

    page.drawText(labelType, {
      x: 70 - fonts.HelveticaBold.widthOfTextAtSize(labelType, 10) / 2,
      y: 23,
      size: 10,
      color: rgb(1, 1, 1),
    });
  }

  // Country of origin
  page.drawText(`Origin: ${countryAbbreviationToFullName(label.coo)}`, {
    x: 42,
    y: 6,
    size: 11.5,
  });
};
