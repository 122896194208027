import React from 'react';
import TextCell from './TextCell.js';

/**
 * Create Text Cell
 * @param props
 * @param {string} props.children - Text to display
 * @param {string} [props.toPath]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const PercentCell = props => {
  const {toPath, className, children} = props;

  const valueAsPercentage = (children * 100).toFixed(2);

  return (
    <TextCell toPath={toPath} className={className}>
      {`${valueAsPercentage}%`}
    </TextCell>
  );
};

export default PercentCell;
