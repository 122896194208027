import React from 'react';
import Navigation from './Navigation.js';

const Layout = props => {
  const {children} = props;

  return (
    <div>
      <Navigation />
      <div
        className={
          'grow order-2 bg-secondary border-l border-lightgray-100 h-full overflow-auto'
        }
      >
        <div className={'min-w-full w-min px-10'}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
