import React from 'react';
import {Link} from 'react-router-dom';

const TdLink = props => {
  const {to, children, className, ...otherProps} = props;
  const Tag = to ? Link : 'div';
  const linkClass = to ? 'td-link' : '';

  return (
    <td className={`${linkClass} ${className}`}>
      <Tag to={props.to} {...otherProps}>
        {children}
      </Tag>
    </td>
  );
};

export default TdLink;
