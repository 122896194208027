import React, {useEffect} from 'react';
import {
  usePostRequest,
  usePostRequestBatch,
} from '../../../hooks/https-hooks.js';
import {Dropdown} from '../../Dropdown.js';

/**
 * Submit a PK confirmation request based on the lines currently selected.
 * @param {Object} props
 * @param {string} props.orderNumber
 * @param {Object} props.dataLines
 * @param {string} props.dataLines.itemId
 * @param {number} props.dataLines.lineNumber
 * @param {boolean} props.dataLines.selectLine
 * @param {boolean} props.dataLines.lineConfirmed
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmLinesDropdown = props => {
  const {orderNumber, lines, setLines, header, setHeader} = props;

  // Hook for handling batch post requests of line confirmations
  const {
    result: lineConfirmationResult,
    sendPost: sendLineConfirmationPost,
    hasStarted: hasLineConfirmationStarted,
    hasFinished: hasLineConfirmationFinished,
  } = usePostRequestBatch(`/api/v1/ns/confirm/status/pk/${orderNumber}`);

  // Hook for updating the main line post request if all lines are marked
  // as confirmed.
  const {sendPost: sendMainlineConfirmation} = usePostRequest(
    `/api/v1/ns/confirm/status/pk/${orderNumber}`
  );

  useEffect(() => {
    // Don't attempt updates if there are no results.
    if (!lineConfirmationResult.length) {
      return;
    }

    // Convert the data back into an object to set the mainline
    const tempDataLines = {};
    lines.forEach(line => {
      tempDataLines[line.itemId] = line;
    });

    // Update individual line statuses
    for (const resultInstance of lineConfirmationResult) {
      if (resultInstance.success) {
        // Process all lines that were part of the update request
        for (const success of resultInstance.successes) {
          tempDataLines[success.itemId].lineConfirmed = success.newValue;
        }
      }
      setLines(tempDataLines);
    }

    // Check if an update needs to occur to the mainline status
    const allReviewed = Object.values(tempDataLines).every(
      value => value.lineConfirmed !== 'Needs Review'
    );
    const allLinesConfirmed = Object.values(tempDataLines).every(
      value => value.lineConfirmed === 'Confirmed'
    );
    // Check if everything is confirmed by
    // checking that nothing is not confirmed
    if (allReviewed) {
      const newStatus = allLinesConfirmed ? 'Confirmed' : 'Rejected';

      // Mark the full order as confirmed.
      const tempDataHeader = {...header};
      tempDataHeader.status = newStatus;
      setHeader(tempDataHeader);

      sendMainlineConfirmation({
        mainline: true,
        updates: [
          {
            newValue: newStatus,
            note: `Previous: ${header.status}`,
          },
        ],
      });
    }
  }, [lineConfirmationResult]);

  const handlePost = newValue => {
    const payloadBatch = [];

    let updates = [];
    if (newValue === 'Confirmed') {
      // Non-confirmed lines should be moved to confirm
      updates = selectedAndUnconfirmed;
    } else if (newValue === 'Rejected') {
      // Confirmed lines should not be moved to rejected
      updates = selectedAndNeedsReview;
    }

    const maxBatchSize = 40;
    for (let i = 0; i < updates.length / maxBatchSize; i++) {
      payloadBatch.push({
        mainline: false,
        updates: updates
          .slice(i * maxBatchSize, (i + 1) * maxBatchSize)
          .map(update => {
            return {
              lineNumber: update.lineNumber,
              itemId: update.itemId,
              note: `Previous: ${update.lineConfirmed}`,
              newValue,
            };
          }),
      });
    }

    sendLineConfirmationPost(payloadBatch);
  };

  // Get selected line counts of certain types
  const selectedAndUnconfirmed = lines.filter(
    line =>
      line.selectLine && line.lineConfirmed !== 'Confirmed' && line.isVisible
  );
  const selectedAndNeedsReview = lines.filter(
    line =>
      line.selectLine && line.lineConfirmed === 'Needs Review' && line.isVisible
  );
  // Get a count of lines that are not confirmed.
  const unconfirmedLineCount = lines.filter(
    line => line.lineConfirmed !== 'Confirmed' && line.isVisible
  ).length;

  // Process correct label for the button.
  let label = 'Set Line Status';
  if (hasLineConfirmationStarted && !hasLineConfirmationFinished) {
    label = 'Processing...';
  } else if (unconfirmedLineCount === 0) {
    label = 'All Lines Confirmed';
  }

  return (
    <Dropdown
      options={[
        {
          label: selectedAndUnconfirmed.length
            ? `Confirm ${selectedAndUnconfirmed.length} Lines`
            : 'No Selected Lines to Confirm',
          value: 'Confirmed',
          type: 'button',
        },
        {
          label: selectedAndNeedsReview.length
            ? `Reject ${selectedAndNeedsReview.length} Lines`
            : 'No Selected Lines to Reject',
          value: 'Rejected',
          type: 'button',
        },
      ]}
      onChange={handlePost}
      disabled={!selectedAndUnconfirmed || !unconfirmedLineCount}
    >
      {label}
    </Dropdown>
  );
};

export default ConfirmLinesDropdown;
