import React from 'react';
import clsx from 'clsx';
import Badge from '../../Badge.js';
import TdLink from '../TdLink.js';

/**
 * Create Badge Icon Cell
 * @param {Object} props
 * @param {Array} [props.badges] - An array of objects describing badges
 * @param {string} [props.toPath]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const BadgeCell = props => {
  const {badges = [], toPath, className} = props;

  return (
    <TdLink to={toPath} className={clsx(className, 'w-[150px]')}>
      <Badge className={'w-full'} badges={badges} />
    </TdLink>
  );
};

export default BadgeCell;
