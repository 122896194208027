import React, {useState} from 'react';
import TdLink from '../TdLink.js';
import {Button} from '../../Button.js';

/**
 * Create a button inside a cell
 * @param {Object} props
 * @param {Object} props.label - Button text display options
 * @param {Object} props.label.clicked - Displays when the button is clicked
 * @param {Object} props.label.notClicked
 *   - Displays when the button is not clicked
 * @param {function} props.onClick - Handle all clicks
 * @param {Object} props.data - All information of a cell's line
 * @param {string} [props.toPath]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const ButtonCell = props => {
  const {toPath, className, label, onClick, data} = props;

  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    if (!clicked) {
      setClicked(true);
      onClick(data, clicked, setClicked);
    }
  };

  return (
    <TdLink to={toPath} className={className}>
      <Button onClick={handleClick} mini={true} inverted={true}>
        {clicked ? label.clicked : label.notClicked}
      </Button>
    </TdLink>
  );
};

export default ButtonCell;
