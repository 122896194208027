import React, {useState, useEffect} from 'react';
import {usePostRequest} from '../hooks/https-hooks.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

/**
 * Create a button instance
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @param {function} props.onClick
 * @param {boolean} [props.mini]
 * @param {boolean} [props.inverted]
 * @param {boolean} [props.showBorder]
 * @param {string} [props.className]
 *  @param {boolean} [props.disabled]
 * @returns {JSX.Element}
 */
const Button = props => {
  const {
    children,
    onClick,
    mini,
    inverted,
    showBorder = true,
    className,
    disabled,
  } = props;

  const sizeStyle = mini
    ? `min-h-[25px] min-w-[80px]`
    : `min-h-[43px] min-w-[150px]`;

  const invertedStyle = inverted
    ? showBorder && `border border-red`
    : `border-none ease-in-out duration-500`;

  let colorClass;
  if (disabled) {
    colorClass = inverted
      ? 'bg-white text-red'
      : 'bg-red opacity-40 text-white font-bold';
  } else {
    colorClass = inverted
      ? 'bg-white text-red hover:bg-red hover:text-white'
      : 'bg-red text-white hover:bg-darkred font-bold';
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        sizeStyle,
        invertedStyle,
        className,
        colorClass,
        disabled ? 'cursor-default' : `cursor-pointer`,
        'rounded px-2'
      )}
    >
      {children || 'Go'}
    </button>
  );
};

const ConfirmationButton = props => {
  /*
   * Attempts to confirm the PO in Netsuite
   */
  const sendConfirmation = () => {
    setConfirmText('Confirming...');
    setConfirmButtonDisabled(true);

    sendPost();

    // TODO: This doesn't update the overview element, so moving pages then
    // moving back causes the PO to appear unconfirmed again.
  };

  /*
   * Setup
   */
  const {result: confirmRequestResult, sendPost} = usePostRequest(props.url);
  const [isConfirmed, setConfirmed] = useState(
    props.confirmed || confirmRequestResult.success
  );
  const [confirmText, setConfirmText] = useState('Confirm');
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  const confirmationStatus = isConfirmed ? (
    <span style={{color: 'green'}}>
      <FontAwesomeIcon icon={faCheck} />
    </span>
  ) : (
    <Button
      disabled={confirmButtonDisabled}
      mini={true}
      inverted={true}
      onClick={() => {
        sendConfirmation();
      }}
    >
      {confirmText}
    </Button>
  );

  // Update button whenever a confirmation POST request is returned.
  useEffect(() => {
    if (confirmRequestResult.success) {
      setConfirmed(true);
    } else {
      setConfirmButtonDisabled(false);
    }
  }, [confirmRequestResult]);

  return confirmationStatus;
};

export {Button, ConfirmationButton};
