import React from 'react';
import clsx from 'clsx';

/**
 *
 * @param props
 * @param {JSX.Element} props.title
 * @param {JSX.Element} props.header
 * @param {JSX.Element} props.children
 * @returns {JSX.Element}
 * @constructor
 */
function Section(props) {
  const {title, header, subtitle, children} = props;

  return (
    <div
      className={clsx(
        `bg-white p-5 mb-10`,
        `shadow-[0px_2px_5px_0px_HSLA(0,0%,84.3%,0.5)]`,
        props.className
      )}
    >
      <div className="sectionHeader flex flex-row content-start gap-2.5">
        <div className={'shrink basis-1/2'}>
          <h2 className={''}>{title}</h2>
          {subtitle}
        </div>
        <div className={'shrink basis-1/2'}>{header}</div>
      </div>
      {children}
    </div>
  );
}

export default Section;
