import React from 'react';
import clsx from 'clsx';
import TdLink from '../TdLink.js';
import DisplayDate from '../../DisplayDate.js';

/**
 * Create Date Cell
 * @param props
 * @param {string} props.children - Date to display
 * @param {string} [props.toPath]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const DateCell = props => {
  const {toPath, className, children} = props;

  return (
    <TdLink to={toPath} className={clsx(className, 'max-w-[150px]')}>
      <DisplayDate disabled={true}>{children}</DisplayDate>
    </TdLink>
  );
};

export default DateCell;
