import {PDFDocument, StandardFonts, rgb, degrees} from 'pdf-lib';
import {prettyDate} from '../modules/components/elements.js';
import {
  countryAbbreviationToFullName,
  countryOfOriginDisplayText,
  createBarcode,
  createQRCode,
  generateLineBreakText,
  prettyNumber,
  scaleFontSizeToFit,
} from './label-generator/utility.js';
import {generatePackLabelWithQRCode} from './label-generator/packLabelWithQRCode.js';
import {loadImage} from './label-generator/loadImage.js';

/**
 * Open a generated PDF.
 * @param pdfDoc
 * @param filename
 * @returns {Promise<void>}
 */
const openPDF = async (pdfDoc, filename) => {
  const pdfBytes = await pdfDoc.save();
  const newBlob = new Blob([pdfBytes], {type: 'application/pdf'});

  // Custom default file names work on Firefox. It will not work on Chromium browsers.
  const fileData = new File([newBlob], filename, {type: 'application/pdf'});
  const fileURL = window.URL.createObjectURL(fileData);
  window.open(fileURL);
};

/**
 * Draw the Tekton logo and address in the top left of a page.
 * @param pdfDoc
 * @param page
 * @returns {Promise<*>}
 */
const drawPageHeader = async (pdfDoc, page) => {
  const pageWritten = page;

  // Load the Helvetica fonts.
  const fontHelvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontHelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // Load Tekton Logo image
  const logoPngUrl = '/tekton-logo-hd.png';
  const logoPng = await loadImage(logoPngUrl);
  const embedLogoPng = await pdfDoc.embedPng(logoPng);
  const embedPngDims = embedLogoPng.scale(1 / 8);

  const tektonAddressFormat =
    '3707 Roger B Chaffee SE' + '\n' + 'Grand Rapids, MI 49548';

  /** Top left header **/
  pageWritten.drawImage(embedLogoPng, {
    x: 0.5 * 72,
    y: 10.4 * 72,
    width: embedPngDims.width,
    height: embedPngDims.height,
  });

  pageWritten.setFont(fontHelveticaBold);
  pageWritten.setLineHeight(12);
  pageWritten.drawText('Tekton, Inc.', {
    x: 2.6 * 72,
    y: 10.5 * 72,
    size: 12,
  });
  pageWritten.setFont(fontHelvetica);
  pageWritten.drawText(tektonAddressFormat, {
    x: 2.6 * 72,
    y: 10.3 * 72,
    size: 11,
  });

  return pageWritten;
};

/*
 * Creates a pdf of labels.
 * @param: labelList: a list of objects where each object contains required details for the form.
 * Documentation: https://pdf-lib.js.org/
 * GitHub: https://github.com/Hopding/pdf-lib
 * 1 unit = 1/72in --> 72unit = 1in
 */
const createLabels = async labelList => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  const [fontHelvetica, fontHelveticaBold] = await Promise.all([
    await pdfDoc.embedFont(StandardFonts.Helvetica),
    await pdfDoc.embedFont(StandardFonts.HelveticaBold),
  ]);

  for (let i = 0; i < labelList.length; i++) {
    // The pack and master labels are almost identical. The difference between master and pack are handled in the master label.

    const is3x2Format =
      labelList[i].labelKey === 'PACK' ||
      labelList[i].labelKey === 'MASTER' ||
      labelList[i].labelKey === 'TOTE';

    const labelNeedsQR =
      is3x2Format &&
      labelList[i].labelKey !== 'TOTE' &&
      labelList[i].displayPOQRCode;

    if (labelNeedsQR) {
      await generatePackLabelWithQRCode({
        label: labelList[i],
        pdfDoc,
        fonts: {
          Helvetica: fontHelvetica,
          HelveticaBold: fontHelveticaBold,
        },
      });
      continue;
    }

    const page = pdfDoc.addPage();

    if (is3x2Format) {
      page.setSize(72 * 3, 72 * 2);

      // Load Tekton Logo label
      const sourcePngUrl = '/templates/TEKTON_Label_inner.png';
      const sourcePng = await loadImage(sourcePngUrl);
      const embedPng = await pdfDoc.embedPng(sourcePng);
      let embedPngDims = embedPng.scale((1 / 300) * 134);

      // If the vendor needs to show the QR as a PO, resize the Tekton logo and add a transaction QR code.
      if (labelList[i].displayPOQRCode) {
        embedPngDims = embedPng.scale((1 / 300) * 105);

        const qrImage = await pdfDoc.embedPng(
          createQRCode(labelList[i].transId, 25)
        );
        const dimQrImage = qrImage.scale(1.25);

        page.drawImage(qrImage, {
          x: 3,
          y: 110,
          width: dimQrImage.width,
          height: dimQrImage.height,
        });
      }

      page.drawImage(embedPng, {
        x: 5,
        y: 5,
        height: embedPngDims.height,
        width: embedPngDims.width,
      });

      // Black horizontal lines
      page.drawRectangle({
        x: 37,
        y: 89,
        width: 150,
        height: 1,
        opacity: 1,
      });
      page.drawRectangle({
        x: 37,
        y: 52,
        width: 150,
        height: 1,
        opacity: 1,
      });
      // Black vertical line
      page.drawRectangle({
        x: 85,
        y: 52,
        width: 1,
        height: 37,
        opacity: 1,
      });
      // Black square
      page.drawRectangle({
        x: 170,
        y: 52,
        width: 38,
        height: 38,
        opacity: 1,
      });

      page.setFont(fontHelvetica);

      // Barcode
      let barcodeImage;
      if (!labelList[i].labelKey.includes('TOTE')) {
        barcodeImage = await pdfDoc.embedPng(
          createBarcode('ITF14', labelList[i].barcode, 35)
        );
      } else {
        barcodeImage = await pdfDoc.embedPng(
          createBarcode('CODE39', labelList[i].barcode, 35)
        );
      }
      page.drawImage(barcodeImage, {
        x: 40,
        y: 16,
        width: 164,
      });
      page.drawText(labelList[i].barcode, {
        x:
          120 -
          fontHelveticaBold.widthOfTextAtSize(labelList[i].barcode, 11) / 2,
        y: 5,
        size: 11,
      });

      page.setFont(fontHelveticaBold);

      // Revision number
      page.drawText(String(labelList[i].revision), {
        x:
          189 -
          fontHelveticaBold.widthOfTextAtSize(
            labelList[i].revision.toString(),
            20
          ) /
            2,
        y: 64,
        size: 20,
        color: rgb(1, 1, 1),
      });

      // Country of origin
      page.drawText(
        `Origin: ${countryAbbreviationToFullName(labelList[i].coo)}`,
        {
          x: 87,
          y: 58,
          size: 11.5,
        }
      );

      // Transaction ID (PO/WO)
      if (labelList[i].transId !== undefined) {
        page.drawText(labelList[i].transId, {
          x: 88,
          y: 73,
          size: 12,
          textAlign: 'center',
        });
      }

      // Item Number
      let itemNumber = labelList[i].itemNumber;
      let salesChannel = itemNumber.substr(itemNumber.length - 2);
      if (salesChannel === '-D' || salesChannel === '-S') {
        // Remove the sales channel from item number
        itemNumber = itemNumber.substr(0, itemNumber.length - 2);
        // Draw sales channel box
        page.drawRectangle({
          x: 170,
          y: 114,
          width: 37,
          height: 26,
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          opacity: 0,
          borderOpacity: 1,
        });

        // Display Sales Channel Letter
        page.drawText(salesChannel[1], {
          x: 180,
          y: 118,
          size: 25,
        });

        const fontSize = itemNumber.length < 10 ? 22 : 17;
        // Display Item Number
        page.drawText(itemNumber, {
          x:
            103 - fontHelveticaBold.widthOfTextAtSize(itemNumber, fontSize) / 2,
          y: 117,
          size: fontSize, // Scale font size based on length of the word.
        });
      } else {
        // Reset sales channel because the item is not one of the sales channels.
        salesChannel = undefined;

        const fontSize = itemNumber.length < 10 ? 22 : 17;
        // Display Item Number
        page.drawText(itemNumber, {
          x:
            120 - fontHelveticaBold.widthOfTextAtSize(itemNumber, fontSize) / 2,
          y: 118,
          size: fontSize, // Scale font size based on length of the word.
        });
      }

      // Item Display Name
      const displayName = generateLineBreakText(
        labelList[i].displayName,
        170,
        2,
        fontHelveticaBold,
        9
      );
      // Calculate the centering offset
      let displayNameOffset = 0;
      if (!displayName.includes('\n')) {
        displayNameOffset =
          fontHelveticaBold.widthOfTextAtSize(displayName, 9) / 2 - 80;
      }

      page.drawText(displayName, {
        x: 40 - displayNameOffset,
        y: 103,
        size: 9,
        lineHeight: 9,
        maxWidth: 170,
      });

      // Draw quantity and adjust size and location if quantity is bigger than 1000.
      page.drawText(`Qty: ${labelList[i].quantity}`, {
        x:
          labelList[i].quantity < 1000
            ? 36
            : labelList[i].quantity < 10000
              ? 34
              : 33,
        y: 58,
        size:
          labelList[i].quantity < 1000
            ? 12
            : labelList[i].quantity < 10000
              ? 11
              : 10,
        color: rgb(0, 0, 0),
      });

      // Master and Tote black boxes
      if (
        labelList[i].labelKey.includes('MASTER') ||
        labelList[i].labelKey.includes('TOTE')
      ) {
        // Create black backdrop around "Master" label
        page.drawRectangle({
          x: 37,
          y: 70,
          width: 46,
          height: 17,
          color: rgb(0, 0, 0),
        });

        let labelType;
        if (labelList[i].labelKey.includes('MASTER')) {
          labelType = 'MASTER';
        } else if (labelList[i].labelKey.includes('TOTE')) {
          labelType = 'TOTE';
        }
        page.drawText(labelType, {
          x: 60 - fontHelveticaBold.widthOfTextAtSize(labelType, 10) / 2,
          y: 75,
          size: 10,
          color: rgb(1, 1, 1),
        });
      }
    } else if (labelList[i].labelKey === 'UPC') {
      page.setFont(fontHelvetica);

      page.setSize(72 * 2.5, 72 * 1);

      // Barcode
      const barcodeImage = await pdfDoc.embedPng(
        createBarcode('UPC', labelList[i].barcode, 20)
      );
      page.drawImage(barcodeImage, {
        x: 0,
        y: 30,
        width: 150,
      });
      page.drawText(labelList[i].barcode, {
        x: 15,
        y: 20,
        size: 10,
      });

      // Tekton Address
      page.drawText('3707 Roger B Chaffee SE\nGrand Rapids, MI 49548', {
        x:
          80 -
          fontHelveticaBold.widthOfTextAtSize('3707 Roger B Chaffee SE', 11) /
            2,
        y: 10,
        size: 6,
        lineHeight: 7,
      });

      // Country of Origin
      page.drawText(
        countryOfOriginDisplayText(
          labelList[i].coo_long ? labelList[i].coo_long : labelList[i].coo
        ),
        {
          x: 88,
          y: 10,
          size: 5.5,
          lineHeight: 7,
          maxWidth: 92,
        }
      );

      const itemNumberFontSize = scaleFontSizeToFit(
        labelList[i].itemNumber,
        72,
        fontHelveticaBold,
        9
      );
      // Item Number
      page.drawText(labelList[i].itemNumber, {
        x: 175,
        y:
          36 -
          fontHelveticaBold.widthOfTextAtSize(
            labelList[i].itemNumber,
            itemNumberFontSize
          ) /
            2,
        size: itemNumberFontSize,
        lineHeight: itemNumberFontSize - 2,
        maxWidth: 72,
        rotate: degrees(90),
        font: fontHelveticaBold,
      });

      // Item Display Name
      const displayName = generateLineBreakText(
        labelList[i].displayName,
        145,
        2,
        fontHelvetica,
        8
      );
      page.drawText(displayName, {
        x: 15,
        y: 62,
        size: 8,
        lineHeight: 8,
        maxWidth: 145,
      });
    }
  }

  let fileName = `Tekton_${labelList[0].transId}`;
  if (labelList.length === 1) {
    fileName = `Tekton_${labelList[0].itemNumber}_`;
    if (labelList[0].labelKey.includes('MASTER')) {
      fileName += 'Master';
    } else if (labelList[0].labelKey.includes('PACK')) {
      fileName += 'Pack';
    } else if (labelList[0].labelKey.includes('UPC')) {
      fileName += 'UPC';
    } else if (labelList[0].labelKey.includes('TOTE')) {
      fileName += 'TOTE';
    }
  }

  await openPDF(pdfDoc, fileName);
};

/**
 * Generate a PDF of a PO and the items on it.
 * @param poData
 * @returns {Promise<void>}
 */
const createPOPdf = async poData => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Load the Helvetica fonts.
  const fontHelvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontHelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // Table header start location and total height
  let tableHeight = 8 * 72; // Only the first page starts at this height because it has more things.
  let tableTotalHeight = 0;

  const colorLightGray = rgb(193 / 255, 193 / 255, 193 / 255);

  let pageCount = 0;
  const pages = [];

  const headerDetails = [
    {
      width: 1 * 72,
      position: 'left',
      value: 'Item #',
    },
    {
      width: 0.22 * 72,
      position: 'left',
      value: 'Rev.',
    },
    {
      width: 2.0 * 72,
      position: 'right',
      value: 'Description',
    },
    {
      width: 0.45 * 72,
      position: 'left',
      value: 'Qty.',
    },
    {
      width: 0.6 * 72,
      position: 'left',
      value: 'Ship Start',
    },
    {
      width: 0.6 * 72,
      position: 'right',
      value: 'Ship End',
    },
    {
      width: 0.75 * 72,
      position: 'right',
      value: 'Master Pack',
    },
    {
      width: 0.38 * 72,
      position: 'right',
      value: 'Pack',
    },
    {
      width: 0.38 * 72,
      position: 'right',
      value: 'Tote',
    },
    {
      width: 0.47 * 72,
      position: 'right',
      value: 'Rate',
    },
    {
      width: 0.65 * 72,
      position: 'right',
      value: 'Amount',
    },
  ];

  // Generate the generic page of a PO PDF
  const generateNewPage = async () => {
    pages[pageCount] = pdfDoc.addPage();
    pages[pageCount].setSize(72 * 8.5, 72 * 11);

    pages[pageCount] = await drawPageHeader(pdfDoc, pages[pageCount]);

    /** Top right header **/
    pages[pageCount].setFont(fontHelveticaBold);
    pages[pageCount].drawText('Purchase Order', {
      x: 5.25 * 72,
      y: 10.4 * 72,
      size: 16,
    });
    pages[pageCount].drawText('Date', {
      x: 5.25 * 72,
      y: 10.15 * 72,
      size: 12,
    });
    pages[pageCount].drawText('PO #', {
      x: 5.25 * 72,
      y: 9.9 * 72,
      size: 12,
    });
    pages[pageCount].drawText('Terms', {
      x: 5.25 * 72,
      y: 9.65 * 72,
      size: 12,
    });

    pages[pageCount].setFont(fontHelvetica);
    pages[pageCount].drawText(prettyDate(poData.header.date), {
      x: 6 * 72,
      y: 10.15 * 72,
      size: 12,
    });
    pages[pageCount].drawText(poData.poNumber, {
      x: 6 * 72,
      y: 9.9 * 72,
      size: 12,
    });
    pages[pageCount].drawText(
      generateLineBreakText(poData.header.terms, 2 * 72, 2, fontHelvetica, 12),
      {
        x: 6 * 72,
        y: 9.65 * 72,
        size: 12,
      }
    );

    /** Table header **/
    pages[pageCount].drawRectangle({
      x: 0.5 * 72,
      y: tableHeight,
      width: 7.5 * 72,
      height: 16,
      color: colorLightGray,
    });

    let xPosition = 0.6 * 72;

    for (let i = 0; i < headerDetails.length; i++) {
      pages[pageCount].drawText(headerDetails[i].value, {
        x: xPosition - 5,
        y: tableHeight + 5,
        size: 8,
        color: rgb(1, 1, 1),
      });
      xPosition += headerDetails[i].width;
    }
  };

  // Create the first page
  await generateNewPage();

  // First page specific features
  pages[0].setFont(fontHelveticaBold);
  pages[0].drawText('Vendor', {
    x: 0.5 * 72,
    y: 9.35 * 72,
    size: 10,
  });
  pages[0].drawText('Ship To', {
    x: 4 * 72,
    y: 9.35 * 72,
    size: 10,
  });

  pages[0].setFont(fontHelvetica);
  pages[0].setLineHeight(11);
  const vendorAddress = poData.header.vendor.address;
  const vendorAddressFormat = `${vendorAddress.addressee}\n${
    vendorAddress.line1
  }${vendorAddress.line2 ? `\n${vendorAddress.line2}` : ''}${
    vendorAddress.line3 ? `\n${vendorAddress.line3}` : ''
  }\n${vendorAddress.city} ${vendorAddress.state} ${
    vendorAddress.zip
  }\n${countryAbbreviationToFullName(vendorAddress.country)}`;
  pages[0].drawText(vendorAddressFormat, {
    x: 0.5 * 72,
    y: 9.2 * 72,
    size: 9,
  });

  const shipToAddress = poData.header.shipTo;
  const shipToAddressFormat =
    `${shipToAddress.addressee}\n` +
    `${shipToAddress.line1}\n` +
    `${shipToAddress.city} ${shipToAddress.state} ${shipToAddress.zip}`;
  pages[0].drawText(shipToAddressFormat, {
    x: 4 * 72,
    y: 9.2 * 72,
    size: 9,
  });

  // Loop through poData
  for (let i = 0; i < poData.lines.length; i++) {
    /** Create an item line in the table **/
    // pages[pageCount].setFont(fontHelvetica);
    pages[pageCount].setLineHeight(9);
    tableTotalHeight += 9;
    let xOffset = 0.5 * 72;

    const lineData = [
      {
        width: 1 * 72,
        position: 'left',
        value: poData.lines[i].name || '',
      },
      {
        width: 0.22 * 72,
        position: 'right',
        value: poData.lines[i].revision?.toString() || '',
      },
      {
        width: 2.0 * 72,
        position: 'left',
        value: poData.lines[i].displayName || '',
      },
      {
        width: 0.45 * 72,
        position: 'right',
        value: prettyNumber(poData.lines[i].quantity, 0),
      },
      {
        width: 0.6 * 72,
        position: 'left',
        value: poData.lines[i].shipWindow?.start || '',
      },
      {
        width: 0.6 * 72,
        position: 'left',
        value: poData.lines[i].shipWindow?.end || '',
      },
      {
        width: 0.75 * 72,
        position: 'right',
        value: prettyNumber(poData.lines[i]?.masterPackQuantity, 0),
      },
      {
        width: 0.38 * 72,
        position: 'right',
        value: prettyNumber(poData.lines[i]?.packQuantity, 0),
      },
      {
        width: 0.38 * 72,
        position: 'right',
        value: prettyNumber(poData.lines[i]?.toteQuantity, 0),
      },
      {
        width: 0.47 * 72,
        position: 'right',
        value: prettyNumber(poData.lines[i].rateUSD),
      },
      {
        width: 0.63 * 72,
        position: 'right',
        value: prettyNumber(poData.lines[i].amount),
      },
    ];

    let lineBreakCount = 0;
    for (let j = 0; j < lineData.length; j++) {
      // Determine if there needs to be a line break
      const text = generateLineBreakText(
        lineData[j].value,
        lineData[j].width - 0.1 * 72,
        8,
        fontHelvetica,
        7
      );

      if (lineBreakCount < (text.match(/\n/g) || []).length) {
        lineBreakCount = (text.match(/\n/g) || []).length;
      }

      let position = 0;
      if (lineData[j].position === 'right') {
        position =
          lineData[j].width -
          fontHelvetica.widthOfTextAtSize(text.split('\n')[0], 7) -
          0.08 * 72;
      }

      pages[pageCount].drawText(text, {
        x: xOffset + 0.06 * 72 + position,
        y: tableHeight - tableTotalHeight,
        size: 7,
      });

      xOffset += lineData[j].width;
    }

    tableTotalHeight += 9 * lineBreakCount;

    // If all data has been printed or it is the end of the page, close the table and draw vertical lines.
    if (
      i + 1 === poData.lines.length ||
      tableHeight - tableTotalHeight < 1 * 72
    ) {
      xOffset = 0.5 * 72;
      // Draw in the bottom horizontal line to close the table
      pages[pageCount].drawRectangle({
        x: xOffset,
        y: tableHeight - tableTotalHeight - 0.02 * 72 - 5,
        width: 7.5 * 72,
        height: 0.02 * 72,
        color: colorLightGray,
      });

      // Draw in the vertical bars
      for (let j = 0; j < lineData.length; j++) {
        pages[pageCount].drawRectangle({
          x: xOffset,
          y: tableHeight - tableTotalHeight - 5,
          width: 0.02 * 72,
          height: tableTotalHeight + 5,
          color: colorLightGray,
        });
        xOffset += lineData[j].width;
      }

      // Draw the last vertical column
      pages[pageCount].drawRectangle({
        x: xOffset,
        y: tableHeight - tableTotalHeight - 5,
        width: 0.02 * 72,
        height: tableTotalHeight + 5,
        color: colorLightGray,
      });
    }

    // If it is the end of the page but more data to print, add another page
    if (
      i + 1 !== poData.lines.length &&
      tableHeight - tableTotalHeight < 1 * 72
    ) {
      pageCount++;
      tableHeight = 9 * 72;
      tableTotalHeight = 0;
      await generateNewPage();
      pages[pageCount].setLineHeight(11);
    }
  }

  //  Loop through all pages
  for (let i = 0; i < pageCount + 1; i++) {
    pages[i].drawText(`Page ${i + 1} of ${pageCount + 1}`, {
      x: 7.5 * 72,
      y: 10.75 * 72,
      size: 9,
    });
  }

  // Draw items total
  pages[pageCount].drawText('Total:', {
    x: 6.7 * 72,
    y: tableHeight - tableTotalHeight - 0.3 * 72,
    size: 9,
    font: fontHelveticaBold,
  });
  pages[pageCount].drawText(`$${prettyNumber(poData.header.total)}`, {
    x: 7.15 * 72,
    y: tableHeight - tableTotalHeight - 0.3 * 72,
    size: 9,
  });

  // Draw PO barcode
  const barcodeImage = await pdfDoc.embedPng(
    createBarcode('CODE128', poData.poNumber, 20)
  );
  pages[pageCount].drawImage(barcodeImage, {
    x: 0.5 * 72,
    y: 0.5 * 72,
    width: 1.5 * 72,
  });

  pages[pageCount].drawText(poData.poNumber, {
    x:
      0.5 * 72 +
      (1.5 * 72 - fontHelvetica.widthOfTextAtSize(poData.poNumber, 9)) / 2, // Center the text
    y: 0.35 * 72,
    size: 9,
  });

  // Open the PDF
  await openPDF(pdfDoc, poData.filename);
};

/**
 * Generate a PDF of a VRA and the items and expenses on it.
 * @param vraData
 * @returns {Promise<void>}
 */
const createVRAPdf = async vraData => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Load the Helvetica fonts.
  const fontHelvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontHelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const pages = [];
  let pageCount = 0;

  const headerDetails = [
    {
      width: 1.1 * 72,
      position: 'left',
      value: 'Item Number',
    },
    {
      width: 2.6 * 72,
      position: 'left',
      value: 'Description',
    },
    {
      width: 1 * 72,
      position: 'left',
      value: 'Return Action',
    },
    {
      width: 1 * 72,
      position: 'center',
      value: 'Quantity Defective',
    },
    {
      width: 0.8 * 72,
      position: 'right',
      value: 'Rate',
    },
    {
      width: 1 * 72,
      position: 'right',
      value: 'Amount',
    },
  ];

  // Table header start location and total height
  let tableHeight = 8 * 72; // Only the first page starts at this height because it has more things.
  let tableTotalHeight = 0;

  const colorLightGray = rgb(193 / 255, 193 / 255, 193 / 255);

  // Generate the generic page of a VRA PDF
  const generateNewPage = async () => {
    pages[pageCount] = pdfDoc.addPage();
    pages[pageCount].setSize(72 * 8.5, 72 * 11);

    pages[pageCount] = await drawPageHeader(pdfDoc, pages[pageCount]);

    /** Top right header **/
    pages[pageCount].setFont(fontHelveticaBold);
    pages[pageCount].drawText('Vendor Credit Request', {
      x: 5.25 * 72,
      y: 10.4 * 72,
      size: 16,
    });

    pages[pageCount].setFont(fontHelvetica);
    pages[pageCount].drawText(vraData.data.vendorNum, {
      x: 5.25 * 72, // Right align text
      y: 10.15 * 72,
      size: 11,
    });
    pages[pageCount].drawText(prettyDate(vraData.data.trandate), {
      x: 5.25 * 72,
      y: 9.9 * 72,
      size: 11,
    });

    /** Table header **/
    pages[pageCount].drawRectangle({
      x: 0.5 * 72,
      y: tableHeight,
      width: 7.5 * 72,
      height: 16,
      color: colorLightGray,
    });

    let xPosition = 0.6 * 72;

    for (let i = 0; i < headerDetails.length; i++) {
      pages[pageCount].drawText(headerDetails[i].value, {
        x: xPosition - 5,
        y: tableHeight + 5,
        size: 8,
        color: rgb(1, 1, 1),
      });
      xPosition += headerDetails[i].width;
    }
  };

  // Generate the first page
  await generateNewPage();

  // First page specific features
  pages[0].setFont(fontHelveticaBold);
  pages[0].drawText('Vendor', {
    x: 0.5 * 72,
    y: 9.35 * 72,
    size: 10,
  });

  pages[0].setFont(fontHelvetica);
  pages[0].setLineHeight(11);
  const vendorAddress = vraData.vendor.address;
  const vendorAddressFormat = `${vendorAddress.addressee}\n${
    vendorAddress.line1
  }${vendorAddress.line2 ? `\n${vendorAddress.line2}` : ''}${
    vendorAddress.line3 ? `\n${vendorAddress.line3}` : ''
  }\n${vendorAddress.city} ${vendorAddress.state} ${
    vendorAddress.zip
  }\n${countryAbbreviationToFullName(vendorAddress.country)}`;
  pages[0].drawText(vendorAddressFormat, {
    x: 0.5 * 72,
    y: 9.2 * 72,
    size: 9,
  });

  // Loop through poData
  for (let i = 0; i < vraData.lines.length; i++) {
    /** Create an item line in the table **/
    // pages[pageCount].setFont(fontHelvetica);
    pages[pageCount].setLineHeight(10);
    tableTotalHeight += 12;
    let xOffset = 0.5 * 72;

    const lineData = [
      {
        width: 1.1 * 72,
        position: 'left',
        value: vraData.lines[i].item ? vraData.lines[i].item : 'Expense',
      },
      {
        width: 2.6 * 72,
        position: 'left',
        value: vraData.lines[i].memo,
      },
      {
        width: 1 * 72,
        position: 'left',
        value: vraData.lines[i].return_action,
      },
      {
        width: 1 * 72,
        position: 'right',
        value: vraData.lines[i].quantity
          ? Math.abs(vraData.lines[i].quantity).toString()
          : '',
      },
      {
        width: 0.8 * 72,
        position: 'right',
        value: prettyNumber(vraData.lines[i].rate),
      },
      {
        width: 0.98 * 72,
        position: 'right',
        value: prettyNumber(Math.abs(vraData.lines[i].amount)),
      },
    ];

    let lineBreakCount = 0;
    for (let j = 0; j < lineData.length; j++) {
      // Determine if there needs to be a line break
      const text = generateLineBreakText(
        lineData[j].value,
        lineData[j].width - 0.1 * 72,
        5,
        fontHelvetica,
        7
      );

      if (lineBreakCount < (text.match(/\n/g) || []).length) {
        lineBreakCount = (text.match(/\n/g) || []).length;
      }

      let position = 0;
      if (lineData[j].position === 'right') {
        position =
          lineData[j].width -
          fontHelvetica.widthOfTextAtSize(text.split('\n')[0], 7) -
          0.08 * 72;
      }

      pages[pageCount].drawText(text, {
        x: xOffset + 0.06 * 72 + position,
        y: tableHeight - tableTotalHeight,
        size: 7,
      });

      xOffset += lineData[j].width;
    }

    tableTotalHeight += 12 * lineBreakCount;

    // If all data has been printed or it is the end of the page, close the table and draw vertical lines.
    if (
      i + 1 === vraData.lines.length ||
      tableHeight - tableTotalHeight < 1 * 72
    ) {
      xOffset = 0.5 * 72;
      // Draw in the bottom horizontal line to close the table
      pages[pageCount].drawRectangle({
        x: xOffset,
        y: tableHeight - tableTotalHeight - 0.02 * 72 - 5,
        width: 7.5 * 72,
        height: 0.02 * 72,
        color: colorLightGray,
      });

      // Draw in the vertical bars
      for (let j = 0; j < lineData.length; j++) {
        pages[pageCount].drawRectangle({
          x: xOffset,
          y: tableHeight - tableTotalHeight - 5,
          width: 0.02 * 72,
          height: tableTotalHeight + 5,
          color: colorLightGray,
        });
        xOffset += lineData[j].width;
      }

      // Draw the last vertical column
      pages[pageCount].drawRectangle({
        x: xOffset,
        y: tableHeight - tableTotalHeight - 5,
        width: 0.02 * 72,
        height: tableTotalHeight + 5,
        color: colorLightGray,
      });
    }

    // If it is the end of the page but more data to print, add another page
    if (
      i + 1 !== vraData.lines.length &&
      tableHeight - tableTotalHeight < 1 * 72
    ) {
      pageCount++;
      tableHeight = 9 * 72;
      tableTotalHeight = 0;
      await generateNewPage();
    }
  }

  //  Loop through all pages
  for (let i = 0; i < pageCount + 1; i++) {
    pages[i].drawText(`Page ${i + 1} of ${pageCount + 1}`, {
      x: 7.5 * 72,
      y: 10.75 * 72,
      size: 9,
    });
  }
  // Draw items total
  pages[pageCount].drawText('Total:', {
    x: 6.7 * 72,
    y: tableHeight - tableTotalHeight - 0.3 * 72,
    size: 9,
    font: fontHelveticaBold,
  });
  pages[pageCount].drawText(
    `$${prettyNumber(parseFloat(vraData.data.amount))}`,
    {
      x: 7.15 * 72,
      y: tableHeight - tableTotalHeight - 0.3 * 72,
      size: 9,
    }
  );

  // Draw last page footer message
  pages[pageCount].drawText(vraData.data.memo, {
    x: 0.5 * 72,
    y: 0.55 * 72,
    size: 10,
  });
  pages[pageCount].drawText(
    'If you have any questions or concerns regarding this credit request, please email procurement@tekton.com.',
    {
      x: 0.5 * 72,
      y: 0.25 * 72,
      size: 10,
    }
  );

  await openPDF(pdfDoc, vraData.filename);
};

export {createLabels, createPOPdf, createVRAPdf};
