import React from 'react';
import $ from 'jquery';
import {Route, Switch, Redirect} from 'react-router-dom';
import {ProtectedRoute} from './ProtectedRoute.js';
import auth from './auth.js';
import Account from './pages/account/page.js';
import {
  Overview,
  OverviewOpenPOs,
  OverviewPriorityItems,
} from './pages/page.js';
import PODetail from './pages/purchaseorder/page.js';
import ItemDetail from './pages/item/page.js';
import OverviewVRATable from './pages/creditrequests/page.js';
import Logout from './pages/logout/page.js';
import ChangePasswordPage from './pages/changepassword/page.js';
import {InputWithShiftingLabel} from './modules/components/TextInput.js';
import {Button} from './modules/components/Button.js';
import OverviewPKTable from './pages/purchasecontract/page.js';
import PKDetail from './pages/purchasecontracts/page.js';

function App() {
  return (
    <div id="container">
      <Switch>
        <Route path="/login" component={LoginScreen} />
        <ProtectedRoute exact path="/" component={Overview} />
        <ProtectedRoute exact path="/openpos" component={OverviewOpenPOs} />
        <ProtectedRoute
          exact
          path="/priorityitems"
          component={OverviewPriorityItems}
        />
        <ProtectedRoute
          exact
          path="/creditrequests"
          component={OverviewVRATable}
        />
        <ProtectedRoute
          exact
          path="/purchasecontracts"
          component={OverviewPKTable}
        />
        <ProtectedRoute exact path="/account" component={Account} />
        <ProtectedRoute path="/purchaseorder" component={PODetail} />
        <ProtectedRoute path="/purchasecontract" component={PKDetail} />
        <ProtectedRoute path="/item" component={ItemDetail} />
        <Route exact path="/logout" component={Logout} />
        <ProtectedRoute
          exact
          path="/changePassword"
          component={ChangePasswordPage}
        />
        <Route path="*" component={() => 'Page Not Found'} />
      </Switch>
    </div>
  );
}

/**
 * Creates a login form
 */
class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticationFinished: false,
      isAuthenticated: false,
      isPassChangeRequired: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  handleInputChange() {
    this.setState({loginError: ''});
  }

  render() {
    if (!this.state.isAuthenticationFinished) {
      auth.isAuthenticated().then(authResult => {
        this.setState({
          isAuthenticationFinished: true,
          isAuthenticated: authResult.authenticated,
          loginError: null,
          isPassChangeRequired: authResult.require_password_change,
        });
      });
      return null;
    } else if (this.state.isAuthenticated && this.state.isPassChangeRequired) {
      return <Redirect to={{pathname: '/changePassword'}} />;
    } else if (this.state.isAuthenticated) {
      return <Redirect to={{pathname: '/'}} />;
    }

    return (
      <div>
        <div id="loginBox">
          <form onSubmit={this.handleFormSubmit}>
            <img src="tekton-logo-hd.svg" />
            <div>Vendor Portal</div>

            <div
              className="warningBox"
              style={
                this.state.loginError
                  ? {visibility: 'visible'}
                  : {visibility: 'hidden'}
              }
            >
              {this.state.loginError ? this.state.loginError : 'No Error'}
            </div>

            <InputWithShiftingLabel
              id="login_username"
              label="Username"
              onChange={this.handleInputChange}
            />
            <InputWithShiftingLabel
              id="login_password"
              label="Password"
              type="password"
              onChange={this.handleInputChange}
            />
            <Button
              onClick={() => {
                auth.login(
                  $('#login_username').val(),
                  $('#login_password').val(),
                  () => {
                    this.props.history.push('/');
                  },
                  errorText => {
                    this.setState({loginError: errorText || ''});
                  }
                );
              }}
            >
              Log In
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default App;
