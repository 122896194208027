import React from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome, faUser, faList} from '@fortawesome/free-solid-svg-icons';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

/**
 * The navigation sidebar
 * @returns {JSX.Element}
 * @constructor
 */
const Navigation = () => {
  return (
    <div
      className={
        'order-1 bg-white flex-none w-72 h-full block border-l border-lightgray-100 overflow-auto'
      }
    >
      <div className={'sticky'}>
        <div
          className={clsx(
            'flex justify-center flex-wrap',
            isProd ? '' : 'bg-yellow-200'
          )}
        >
          <a className={'block w-4/5 my-[15px]'} href="/">
            <img className={'block w-full'} src="../tekton-logo-hd.svg" />
            <div className={'text-left w-4/5 text-lg text-red ml-2.5'}>
              Vendor Portal
            </div>
          </a>
        </div>
        <NavigationSelector
          icon={faHome}
          label="Overview"
          className={'border-t'}
          target="/"
        />
        <NavigationSelector icon={faList} label="Open POs" target="/openpos" />
        <NavigationSelector
          icon={faList}
          label="Priority Items"
          target="/priorityitems"
        />
        <NavigationSelector
          icon={faList}
          label="Vendor Credit Requests"
          target="/creditrequests"
        />
        <NavigationSelector
          icon={faList}
          label="Purchase Contracts"
          target="/purchasecontracts"
        />
        <NavigationSelector
          icon={faUser}
          label="My Account"
          target="/account"
        />
      </div>
    </div>
  );
};

/**
 * An individual navigation option
 * @param props
 * @param {} props.icon
 * @param {JSX.Element|string} props.label
 * @param {string} props.target
 * @param {Object} [props.className]
 * @returns {JSX.Element}
 * @constructor
 */
const NavigationSelector = props => {
  const {icon, label, target, className} = props;

  return (
    <Link
      className={clsx(
        'py-3 w-full block border-b border-lightgray-100 hover:bg-lightgray-100',
        className
      )}
      to={target}
    >
      <div className={'pl-6'}>
        <FontAwesomeIcon icon={icon} className={'mr-2'} />
        {label}
      </div>
    </Link>
  );
};

export default Navigation;
