import React, {useState} from 'react';
import {Route, Redirect} from 'react-router-dom';
import auth from './auth.js';

export const ProtectedRoute = ({component: Component, ...rest}) => {
  const [isAuthenticationFinished, setIsAuthenticationFinished] =
    useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPasswordChangeRequired, setPasswordChangeRequired] = useState(false);

  auth.isAuthenticated().then(authResult => {
    setIsAuthenticated(authResult.authenticated);
    setPasswordChangeRequired(authResult.require_password_change);
    setIsAuthenticationFinished(true);
  });

  if (!isAuthenticationFinished) {
    return null;
  } else if (!isAuthenticated) {
    console.log('❌ Unauthorized. Redirecting to login.');
    return <Redirect to={{pathname: '/login'}} />;
  } else if (isPasswordChangeRequired && {...rest}.path !== '/changePassword') {
    // The path check is important because without it, rendering the
    // ProtectedRoute for changePassword would find that the password still
    // needs to be changed, so it would redirect the changePassword page to
    // the changePassword page in an infinite loop.
    return <Redirect to={{pathname: '/changePassword'}} />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} />;
      }}
    />
  );
};
