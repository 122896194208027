import React from 'react';
import Layout from '../modules/components/layout/Layout.js';
import {Table} from '../modules/components/table/Table.js';
import {useGetRequest} from '../modules/hooks/https-hooks.js';
import {ITEM_PRIORITY_BADGES} from '../modules/configuration/itemPriorityBadges.js';

/**
 *
 */
function Overview() {
  return (
    <Layout>
      <h1>Overview</h1>
      <OpenPOList pageSize={5}></OpenPOList>
      <PriorityItemList
        pageSize={5}
        highPriorityOnly={true}
        title={'High Priority Items'}
      ></PriorityItemList>
    </Layout>
  );
}

/**
 * Returns an overview page of all open POs.
 */
function OverviewOpenPOs() {
  return (
    <Layout>
      <h1>Open Purchase Orders List</h1>
      <OpenPOList pageSize={50}></OpenPOList>
    </Layout>
  );
}

/**
 * Returns an overview page of item priorities
 */
function OverviewPriorityItems() {
  return (
    <Layout>
      <h1>Priority Items List</h1>
      <PriorityItemList pageSize={50} title={'Items'}></PriorityItemList>
    </Layout>
  );
}

const OpenPOList = props => {
  const {data, hasFinished: hasFinished} = useGetRequest('/api/v1/ns/openPOs');

  return (
    <Table
      pageSize={props.pageSize}
      data={data?.data || {}}
      hasDataLookupFinished={hasFinished}
      title="Open Purchase Orders (POs)"
      lineFormat={[
        {
          key: 'number',
          header: 'Purchase Order',
          type: 'text',
          align: 'left',
        },
        {
          key: 'date',
          header: 'Date',
          type: 'date',
          align: 'left',
        },
        {
          key: 'manufacturer',
          header: 'Manufacturer',
          type: 'text',
          align: 'left',
        },
        {
          key: 'confirmed',
          header: (
            <>
              CONFIRMED
              <br />
              BY VENDOR
            </>
          ),
          type: 'confirmationbutton',
          align: 'center',
          options: {
            url: '/api/v1/ns/confirm/mainline/po',
            payload: {key: 'number'},
          },
        },
      ]}
      lineSettings={{
        to: {
          url: '/purchaseorder/',
          keyToAppend: 'number',
        },
      }}
      searchFilters={[
        {
          key: 'number',
          type: 'string',
        },
        {
          key: 'date',
          type: 'date',
        },
        {
          key: 'manufacturer',
          type: 'string',
        },
      ]}
      sortCriteria={[
        {
          key: 'date',
          type: 'date',
          direction: 'descending',
        },
        {
          key: 'number',
          type: 'string',
          direction: 'descending',
        },
      ]}
      checkboxFilters={[
        {
          key: 'confirmed',
          condition: {
            type: 'equals',
            values: false,
          },
          label: 'Unconfirmed POs Only',
          value: false,
        },
      ]}
    ></Table>
  );
};

const PriorityItemList = props => {
  const highPriorityOnly = props.highPriorityOnly
    ? props.highPriorityOnly
    : false;

  const {data, hasFinished} = useGetRequest(
    `/api/v1/ns/itemPriorities/${highPriorityOnly}`
  );

  return (
    <Table
      pageSize={props.pageSize}
      data={data?.data || {}}
      hasDataLookupFinished={hasFinished}
      title={props.title}
      lineFormat={[
        {
          key: 'itemNumber',
          header: 'Item',
          type: 'text',
          align: 'left',
        },
        {
          key: 'expectedShipDate',
          header: 'Expected Ship Date',
          type: 'date',
          align: 'left',
        },
        {
          key: 'manufacturer',
          header: 'Manufacturer',
          type: 'text',
          align: 'left',
        },
        {
          key: 'quantityOutstanding',
          header: (
            <>
              Quantity
              <br />
              Outstanding
            </>
          ),
          type: 'text',
          align: 'center',
        },
        {
          key: 'priority',
          header: `PRIORITY`,
          type: 'badge',
          align: 'center',
          options: ITEM_PRIORITY_BADGES,
        },
      ]}
      lineSettings={{
        to: {
          url: '/item/',
          keyToAppend: 'itemNumber',
        },
      }}
      searchFilters={[
        {
          key: 'itemNumber',
          type: 'string',
        },
        {
          key: 'expectedShipDate',
          type: 'date',
        },
        {
          key: 'manufacturer',
          type: 'string',
        },
      ]}
      sortCriteria={[
        {
          key: 'priority',
          type: 'number',
          direction: 'ascending',
        },
        {
          key: 'expectedShipDate',
          type: 'date',
          direction: 'ascending',
        },
        {
          key: 'itemNumber',
          type: 'string',
          direction: 'ascending',
        },
      ]}
      checkboxFilters={[]}
    ></Table>
  );
};

export {Overview, OverviewOpenPOs, OverviewPriorityItems};
