import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {Table} from '../Table.js';
import ButtonCell from '../cell/ButtonCell.js';
import BadgeCell from '../cell/BadgeCell.js';
import CheckboxCell from '../cell/CheckboxCell.js';
import ConfirmationButtonCell from '../cell/ConfirmationButtonCell.js';
import DateCell from '../cell/DateCell.js';
import PercentCell from '../cell/PercentCell.js';
import SublineCollapseCell from '../cell/SublineCollapseCell.js';
import TextCell from '../cell/TextCell.js';
import {CurrencyCell} from '../cell/CurrencyCell.js';

/**
 * Standard line for a table.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Line = props => {
  const {updateSingleCell} = props;

  const [visible, setVisible] = useState(true);
  const [rowHighlight, setRowHighlight] = useState('');

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    setRowHighlight(props.highlightClass);
  }, [props.highlightClass]);

  const cells = [];
  const subLines = [];

  // How many columns there are total.
  const colspan = props.lineFormat.length;

  props.lineFormat.forEach(column => {
    // Create a link path if the table line has one available in the settings.
    const toPath = props.lineSettings?.to
      ? props.lineSettings.to.url +
        props.data[props.lineSettings.to.keyToAppend]
      : null;

    // Determine styling for this cell
    let alignment;
    if (column.align === 'right') {
      alignment = 'text-right';
    } else if (column.align === 'center') {
      alignment = 'text-center';
    }

    // Get the unique value of the cell.
    const value = props.data[column.key];
    // Create a unique key for the cell
    const key = value + column.key;

    // Determine what type of cell to create
    if (column.type === 'text' || !column.type) {
      cells.push(
        <TextCell key={key} className={alignment} toPath={toPath}>
          {value}
        </TextCell>
      );
    } else if (column.type === 'percent') {
      cells.push(
        <PercentCell key={key} className={alignment} toPath={toPath}>
          {value}
        </PercentCell>
      );
    } else if (column.type === 'currency') {
      cells.push(
        <CurrencyCell
          key={key}
          className={alignment}
          toPath={toPath}
          amount={value}
        ></CurrencyCell>
      );
    } else if (column.type === 'options') {
      cells.push(
        <TextCell className={alignment} toPath={toPath} key={key}>
          {column.options[value]}
        </TextCell>
      );
    } else if (column.type === 'date') {
      cells.push(
        <DateCell key={key} className={alignment} toPath={toPath}>
          {value}
        </DateCell>
      );
    } else if (column.type === 'badge') {
      cells.push(
        <BadgeCell
          key={key}
          toPath={toPath}
          badges={column.options[value]}
          className={alignment}
        />
      );
    } else if (column.type === 'confirmationbutton') {
      cells.push(
        <ConfirmationButtonCell
          key={key}
          toPath={toPath}
          // Create the url and append the dynamic order number
          url={`${column.options.url}/${props.data[column.options.payload.key]}`}
          confirmed={value}
          className={alignment}
        >
          {value}
        </ConfirmationButtonCell>
      );
    } else if (column.type === 'button') {
      cells.push(
        <ButtonCell
          key={key}
          toPath={toPath}
          className={alignment}
          onClick={column.options.onClick}
          label={column.options.label}
          data={props.data}
        >
          {value}
        </ButtonCell>
      );
    } else if (column.type === 'checkbox') {
      cells.push(
        <CheckboxCell
          className={alignment}
          toPath={toPath}
          key={key}
          checked={value}
          onChange={changeValue =>
            updateSingleCell(changeValue, column.key, props.objKey)
          }
        />
      );
    } else if (column.type === 'collapsingSubline') {
      const [open, setOpen] = useState(false);

      // Only show the button if there is a value present
      cells.push(
        value.length !== 0 ? (
          <SublineCollapseCell
            className={alignment}
            open={open}
            setOpen={setOpen}
            key={key}
          />
        ) : (
          <td key={key}></td>
        )
      );

      if (open) {
        subLines.push(
          <td colSpan={colspan} key={key}>
            <Table
              className={'mx-2 mb-2'}
              pageSize={Infinity}
              data={{...value}}
              hasDataLookupFinished={true}
              title={column.options.title}
              lineFormat={column.options.lineFormat}
              searchFilters={column.options.searchFilters}
              sortCriteria={column.options.sortCriteria}
              showPagination={false}
            />
          </td>
        );
      }
    } else {
      cells.push(<td key={'invalid'}>Invalid Cell Data</td>);
    }
  });

  return (
    <>
      <tr
        className={clsx(
          `whitespace-normal hover:bg-accent-2`,
          rowHighlight ? 'bg-accent' : 'bg-primary',
          visible ? '' : 'object-none'
        )}
      >
        {cells}
      </tr>
      <tr
        className={clsx(
          `whitespace-normal w-full`,
          rowHighlight ? 'bg-accent' : 'bg-primary',
          visible ? '' : 'object-none'
        )}
      >
        {subLines}
      </tr>
    </>
  );
};

export default Line;
