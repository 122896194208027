import React from 'react';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {Button} from './Button.js';

/**
 * Creates a dropdown with many different inline components
 * @param props
 * @param {JSX.Element} props.children - Name of dropdown
 * @param {array} props.options
 * @param {function} props.onChange
 * @param {boolean} [props.disabled]
 * @returns {JSX.Element}
 * @constructor
 */
const Dropdown = props => {
  const {options = [], onChange, children, disabled} = props;

  const elementOptions = [];

  for (const option of options) {
    if (option.type === 'button') {
      elementOptions.push(
        <Button
          disabled={disabled}
          key={option.label}
          className={'w-full block my-0.5 hover:bg-accent hover:text-red'}
          inverted={true}
          mini={true}
          showBorder={false}
          onClick={() => {
            onChange(option.value);
          }}
        >
          {option.label}
        </Button>
      );
    }
  }

  return (
    <div className="group relative inline-block text-left group">
      <Button disabled={disabled}>
        {children}
        <FontAwesomeIcon icon={faChevronDown} />
      </Button>
      <div
        className={clsx(
          'absolute left-0 z-10 min-w-[200px] rounded-md bg-white shadow-lg',
          'ring-1 ring-black ring-opacity-5 focus:outline-none drop-shadow-2xl',
          `hidden ${disabled ? '' : 'group-hover:block'}`
        )}
      >
        <div className={'px-1 py-0.5 rounded'}>{elementOptions}</div>
      </div>
    </div>
  );
};

export {Dropdown};
